import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import './styles.scss';
import { Column, Row } from '../Grid';
import Section from '../Section';
/* Set state from dropdown options
Keyword search
Display only options that match both State and Search.

Possible state options: 
State = [CTE] OR [NGSS] + [subcategory] + [specific]
ex: CTE + A1. + 6
  Filter CTE A1.6
  NGSS+ PS + 1C 
*/

const CTEFilter = {};

const ResourceSearch = ({
  clearFilter,
  clearLabel,
  filterChangeHandler,
  filterValue,
  placeHolder,
  searchApplied,
  searchAppliedHandler,
  searchLabel,
  searchResultsLabel,
}) => {
  return (
    <Section className="pt-0 pb-0">
      <Row className="resourcesearch__labels mb-0">
        <Column size={6}>
          <div>
            <h3 className="h3">
              KEYWORD <span>Enter keywords below</span>
            </h3>
          </div>
          <div className="resourcesearch__input_container">
            <input
              className="resourcesearch__input"
              type="text"
              value={filterValue}
              onChange={filterChangeHandler}
              placeholder={`${placeHolder}`.toUpperCase()}
            />
          </div>
        </Column>
        <Column size={6}>
          <h3 className="h3">
            SEARCH <span>Select the button to see the results</span>
          </h3>
          <div>
            <Button
              className="resourcesearch__search_button"
              onClick={searchAppliedHandler}
            >
              {searchLabel}
            </Button>
          </div>
        </Column>
      </Row>
      {searchApplied && (
        <div className="resourcesearch__clear_container">
          <h4 className="resourcesearch__clear_text mb-0">
            {`${searchResultsLabel}`}
            {/* <i>{`'${filterValue}'`}</i> */}
          </h4>
          <Button
            className="resourcesearch__clear_button"
            onClick={clearFilter}
          >
            {clearLabel}
          </Button>
        </div>
      )}
    </Section>
  );
};

ResourceSearch.propTypes = {
  clearFilter: PropTypes.func,
  clearLabel: PropTypes.string,
  filterChangeHandler: PropTypes.func,
  filterValue: PropTypes.string,
  placeHolder: PropTypes.string,
  searchApplied: PropTypes.bool,
  searchAppliedHandler: PropTypes.func,
  searchLabel: PropTypes.string,
  searchResultsLabel: PropTypes.string,
};

export default ResourceSearch;
