import React, { useState } from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import { graphql } from 'gatsby';
import '../styles/abstracts/_imports.scss';

import { RESOURCE_FILTER_FIELDS } from '../utils/constants';
import { getKeyedResources, getRawResources } from '../utils/resource-helper';
import Resource from '../components/Resource';
import ResourceSearch from '../components/ResourceSearch';

const renderRowOfResources = (resources) => {
  return (
    <Row key={resources[0].title + resources[0].slug}>
      {resources.map((res, index) => (
        <Column size={4} key={res.title + index} className="search-function">
          <Resource
            unit={res.unit}
            unitPath={res.unitPath}
            tophat={res.tophat}
            tophatClass={`${res.page}__resource-tophat`}
            ribbon={res.ribbon}
            ribbonClass={`${res.page}__resource-ribbon`}
            img={res.img}
            duration={res.duration}
            subject={res.subject}
            subjectClass={`${res.page}__resource-subject`}
            title={res.title}
            description={res.description}
            actions={res.actions}
          />
        </Column>
      ))}
    </Row>
  );
};

const renderFilteredResources = (filteredResources, noResourcesFound) => {
  if (!filteredResources.length) {
    return noResourcesFound;
  }

  return (
    <>
      {filteredResources
        .reduce((acc, curr, index) => {
          const accIndex = Math.floor(index / 3);
          if (acc[accIndex] === undefined) {
            acc[accIndex] = [curr];
          } else {
            acc[accIndex].push(curr);
          }
          return acc;
        }, [])
        .map((resRow) => renderRowOfResources(resRow))}
    </>
  );
};

const Search = ({ data, pageContext }) => {
  const allResources = getRawResources(data);
  const keyedResources = getKeyedResources(data);
  const [resources, setResources] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [searchApplied, setSearchApplied] = useState(false);

  const filterChangeHandler = (event) => setFilterValue(event.target.value);
  const clearFilter = () => {
    setFilterValue('');
    setSearchApplied(false);
  };
  const searchAppliedHandler = () => {
    if (filterValue) {
      const filteredResources = allResources.filter((res) =>
        RESOURCE_FILTER_FIELDS.some((field) =>
          res[field].toLowerCase().includes(filterValue.toLowerCase())
        )
      );
      setResources(filteredResources);
      setSearchApplied(true);
    }
  };

  const {
    //AG Resources//
    agSolutionSeekingMicrobesIntro,
    agSuperheroMicrobes,
    agYogurtFermentation,
    agMicrobesAndFood,
    agAntibioticsResistanceCombatingDisease,
    agBacterialDefense,
    agUsesOfCrisprAndBioethicalDecisionMaking,
    agDetectingWolbachia,
    agMicrobesAndBalanceInTheEnvironment,
    agDesignThinkingToIdentifyChallenges,
    agMicrobesToTheRescue,
    agAlternativeProteinsIntro,
    agIdentifyingGmos,
    agDetectingGeneticallyEngineeredCrops,
    agDnaToAlternativeProteins,
    agGoldenRiceCaseStudy,
    agBioethicsDebate,
    agIndustriesWithGeTechnology,
    agProjectRollout,
    agCommunityOutreach,
    agSustainabilityAndManufacturing,
    agAdCampaign,
    agPlantToPharmaceuticalIntro,
    agBiodiversityOnEarth,
    agEthicalCollaboration,
    agBotanicalCollectionsAndInaturalist,
    agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment,
    agDrugDiscoveryUsingPlantExtracts,
    agPlantAndDisease,
    agPlantMedicineProductDevelopment,
    agProductDevelopmentCollaborationFeedbackAndRevision,
    agProductShowcase,
    agCommunityScienceIntro,
    agCommunityApplicationOfDnaIdentification,
    agProposalDevelopmentLessonOne,
    agProposalDevelopmentLessonTwo,
    agProposalDevelopmentLessonThree,
    agDnaTechnologyLessonOne,
    agDnaTechnologyLessonTwo,
    agDnaTechnologyLessonThree,
    agDnaTechnologyLessonFour,
    agDecisionTreeCreation,
    agDnaCollectionKitDesign,
    agFinalArtifact,
    //Biomed Resources//
    bmCrowdsourcingInnBiotechIntro,
    bmCellBiologyBiomarkers,
    bmMitosisMeiosis,
    bmErrorsCellReplication,
    bmCellDamage,
    bmCulturingCells,
    bmInfectiousDiseases,
    bmChronicDiseases,
    bmDiagnosingDiabetes,
    bmDataGenHealthyDiseasedPatients,
    bmStatSignificantBiomarkers,
    bmWearableDevicesDataCollection,
    bmCurrentInfectiousDiseases,
    bmActionCommunityIntro,
    bmEpidemiology,
    bmTreatingBacterialViralDisease,
    bmNewProductionMethods,
    bmManufacturingMedicine,
    bmElizaVaccineTrials,
    bmVaccineProductionSafetyTesting,
    bmPublicHealthAgencies,
    bmSocialAwarenessCampaignProject,
    bmNucleicAcidsProteinsIntro,
    bmCentralDogmaBiology,
    bmDnaModification,
    bmDnaIsolationAndPurification,
    bmProteinModification,
    bmProteinIsolationPurification,
    bmGeneticProtein,
    bmNucleicAcidAssays,
    bmProteinAssays,
    bmHowAreDrugsTested,
    bmMechanismsDrugDelivery,
    bmMysteryDiseaseConference,
    bmBehindScientificBreakthroughs,
    bmCellularAging,
    bmGeneticSequencing,
    bmHumanGenomeProject,
    bmCanOrganismHaveNoParents,
    bmBioengineeringOrganisms,
    bmBioengineeringPlants,
    bmTherapeuticCloningEmbryonicStemCells,
    bmLongevityMarkers,
    bmSenolyticsOurWarAgainstAging,
    bmEthicalConsiderationsLongevity,
    //Chem Resources//
    chemAntibodyAvengers,
    chemBenefitsOfBiologics,
    chemCancerAndCompanionDiagnostics,
    chemGoingGreenInBiopharma,
    //Living Earth Resources//
    leCommunicatingGeneticTestReport,
    leGeneticCounseling,
    leGeneticDetectivesIntro,
    leGeneticDetectivesJournal,
    leGenetics,
    leGeneticMutations,
    leInheritedDiseases,
    leTreatingInheritedDisease,
    leCommunityEmpowermentIntro,
    leCommunityEmpowermentJournal,
    leCommunityEmpowerment,
    leDiseaseImpact,
    leDiseaseTransmission,
    leInfectiousDiseases,
    lePersistenceOfDisease,
    leTreatmentPrevention,
    //Physics Resources//
    physApplyingSpectroscopy,
    physGeneratingTheFuture,
    physRidingTheWave,
    physUncoveringPlasmasPromise,
    //Student Interactive Resources//
    stAntigenAttack,
    stBacteriaCulture,
    stBloodGlucose,
    stCellularEnergetics,
    stConservationGenetics,
    stFromDnaToProtein,
    stHowWeAge,
  } = keyedResources;

  const fadeInDelays = [0, 300, 600];

  const removeARDX = true;

  return (
    <Layout title="Search">
      <Hero
        mediaSource={'header-search.jpg'}
        className={'hero--large hero--search'}
        color={'white'}
      >
        <div>
          <h1>SEARCH</h1>
          {/* <h3 className="home__hero-copy">Waiting on Copy</h3> */}
        </div>
      </Hero>

      {/* SEARCH */}
      <Section>
        <Container className="search">
          <h2 className="home__bold-heading">Search Options</h2>
          <ResourceSearch
            clearFilter={clearFilter}
            clearLabel={'Clear Search'}
            filterChangeHandler={filterChangeHandler}
            filterValue={filterValue}
            placeHolder={'Enter Keyword'}
            searchApplied={searchApplied}
            searchAppliedHandler={searchAppliedHandler}
            searchLabel={'Search'}
            searchResultsLabel={'Search Results'}
          />
        </Container>
        {searchApplied ? (
          <Section className="mt-0 pt-0">
            <Container>{renderFilteredResources(resources)}</Container>
          </Section>
        ) : (
          <></>
        )}
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query SearchResources {
    allResourcesJson {
      edges {
        node {
          slug
          unit
          unitPath
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
            }
          }
        }
      }
    }
  }
`;

export default Search;
